import { getClassNamesFromArray } from '@common/utils';
import './styles.less';
const Brand = svs.ui.ReactBrand;
const SiteHeaderBrand = ({
  isDarkText,
  size
}) => <div className={`site-header-brand header-title header-title-text header-${size}`}>
    <a className="js-top-center-content header-title-content header-title-content-1-rows" href="/">
      <span className="header-row-1 header-row-text">
        <span>
          <Brand emblem size={size === 'large' ? 300 : 150} />
        </span>
        <span className="header-title-content-divider" />
        <span className={getClassNamesFromArray(['header-title-content-text', isDarkText && 'fc-navy'])}>
          Ombudsportalen
        </span>
      </span>
    </a>
  </div>;
SiteHeaderBrand.defaultProps = {
  size: 'medium'
};
SiteHeaderBrand.propTypes = {
  isDarkText: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large'])
};
export default SiteHeaderBrand;