const {
  cdn
} = svs.core.config.data.svsconfig;
export const getClassNamesFromArray = arr => arr.filter(c => Boolean(c)).join(' ');
export const getContrastColor = hexColor => {
  if (!hexColor) {
    return '#000000';
  }
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000' : '#ffffff';
};
export const formatUserRole = role => {
  switch (role) {
    case 'ADMIN_RETAILER':
      return 'Admin';
    case 'RETAILER_SALES':
      return 'Sälj';
    case 'RETAILER_LOGIN':
      return 'Bas';
    default:
      return '';
  }
};
export const isUserAdmin = () => {
  return svs.core.userSession.hasRole('AdminRetailer');
};
export const isUserAdminOrSales = svs.core.userSession.hasRole('RetailerSales') || svs.core.userSession.hasRole('AdminRetailer');
export const formatDatePeriod = (startDate, endDate) => {
  if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
    throw Error(`formatDatePeriod: input args has to be instance of Date`);
  }
  let startDateFormat;
  if (startDate.getYear() !== endDate.getYear()) {
    startDateFormat = 'dd/M, yyyy';
  } else if (startDate.getMonth() === endDate.getMonth()) {
    startDateFormat = 'dd';
  } else {
    startDateFormat = 'dd/M';
  }
  const endDateFormat = 'dd/M, yyyy';
  if (startDate.getUTCDate() === endDate.getUTCDate() && startDate.getUTCMonth() === endDate.getUTCMonth() && startDate.getUTCFullYear() === endDate.getUTCFullYear()) {
    return dateFns.format(endDate, endDateFormat);
  }
  return `${dateFns.format(startDate, startDateFormat)} - ${dateFns.format(endDate, endDateFormat)}`;
};
export const getDiffColorClass = diff => ['fc-red', '', 'fc-green'][Math.sign(diff) + 1];
export const downloadBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};
export const formatPersonalNumber = ssn => ssn.replace(/^(\d{8})(\d{4})$/, '$1-$2');
export const formatPhone = phone => {
  if (phone.slice(0, 2) === '07') {
    return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1-$2 $3 $4');
  }
  if (phone.slice(0, 2) === '08') {
    switch (phone.length) {
      case 8:
        return phone.replace(/^(\d{2})(\d{2})(\d{2})(\d{2})$/, '$1-$2 $3 $4');
      case 9:
        return phone.replace(/^(\d{2})(\d{3})(\d{2})(\d{2})$/, '$1-$2 $3 $4');
      case 10:
        return phone.replace(/^(\d{2})(\d{3})(\d{3})(\d{2})$/, '$1-$2 $3 $4');
      default:
        return phone;
    }
  }
  switch (phone.length) {
    case 8:
      return phone.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, '$1-$2 $3 $4');
    case 9:
      return phone.replace(/^(\d{3})(\d{2})(\d{2})(\d{2})$/, '$1-$2 $3 $4');
    case 10:
      return phone.replace(/^(\d{3})(\d{3})(\d{2})$/, '$1-$2 $3');
    default:
      return phone;
  }
};
export const sanitizeRetailerId = retailerId => {
  const logger = svs.core?.log?.getLogger('common:sanitizeRetailerId');
  const sanitized = retailerId.replace(/^0+/, '').replace(/[^0-9]/g, '');
  if (retailerId.length === 5 && logger) {
    logger.info('retailerId is only five characters, it may be a terminalnummer.', {
      retailerId: sanitized
    });
  }
  return sanitized;
};
export const useQuery = () => {
  const {
    search
  } = ReactRouterDOM.useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
export const buildPathFromQueryParams = ({
  endpoint,
  queryParamObj
}) => {
  const queryParams = Object.keys(queryParamObj).reduce((acc, key) => acc + `${key}=${queryParamObj[key]}&`, '').slice(0, -1);
  return `${endpoint}` + (queryParams ? `?${queryParams}` : '');
};
export const getRetailerIdIssueProposal = retailerId => {
  const ridLength = retailerId.trim().replace(/[^0-9]/g, '').length;
  if (ridLength === 5) {
    return `Har du möjligtvis fyllt i ditt terminalnummer istället för ombudsnummer?<br /><br />`;
  } else if (ridLength === 10 || ridLength === 12) {
    return `Har du möjligtvis fyllt i ditt personnummer istället för ombudsnummer?<br /><br />`;
  }
  return '';
};
const getRgbFromHex = hexColor => {
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);
  return `${r},${g},${b}`;
};
const getSemiTransparentColor = (color, opacity = 0.5) => {
  const rgbColor = getRgbFromHex(color);
  return `rgba(${rgbColor},${opacity})`;
};
export const getImgValue = (backgroundImageSrc, backgroundColor) => {
  const bgColor = backgroundImageSrc ? getSemiTransparentColor(backgroundColor) : backgroundColor;
  const props = [`to right, ${bgColor}, ${bgColor}`, backgroundImageSrc && `url(${cdn + backgroundImageSrc}`].filter(p => Boolean(p)).join('), ');
  return `linear-gradient(${props})`;
};